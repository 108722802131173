<template>
  <div class="p-version">
    <div class="p-version__logo">
      <img src="/assets/img/logo01.png" alt="" />
    </div>
    <div v-if="version" class="c-title2 c-title2--center c-title2--mb8">
      version {{ version }}
    </div>
  </div>
</template>

<script>
import { useNativeConnection } from '@/composables/useNativeConnection';
const { getInstalledAppVersion } = useNativeConnection();

export default {
  data: () => ({
    version: null
  }),

  async created() {
    const { version } = await getInstalledAppVersion();
    this.version = version;
  }
};
</script>
