var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-version" }, [
    _vm._m(0),
    _vm.version
      ? _c("div", { staticClass: "c-title2 c-title2--center c-title2--mb8" }, [
          _vm._v(" version " + _vm._s(_vm.version) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-version__logo" }, [
      _c("img", { attrs: { src: "/assets/img/logo01.png", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }